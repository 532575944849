#root{
  width: 100%;
  height: 100%;
}
.header {
  color: white;
}

.titulo-servico {
  font-size: 1.4em;
}

.descr-servico {
  font-size: 1.2em;
}

.contactoStyle {
  z-index: 1;
  position: relative;
  padding-right: 0;
  padding-left: 0;
  border: none;
  border-radius: 0;
  font-size: 1.5em;
  background: none;
  box-shadow: none !important;
  resize: none;
}

.divContactoLine {
  border-bottom: 1px solid #e1e1e1;
}

footer {
  /*background: url(../img/bg.jpg) no-repeat center center scroll;*/
  background-color: #F5F5F5;
}

.btn-contacto {
  border: 1px solid #222;
  background-color: transparent;
  font-weight: 900;
  padding: 12px 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0;
}

.logoSVG {
  width: 70%;
}

@media (max-width: 768px) {
  .logoSVG {
      width: 100%;
  }
        .divRowContacto {
      margin-left: 0px;
      margin-right: 0px;
  }
}